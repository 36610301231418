import Fast from "assets/media/gifs/retail/Fast.gif";
import Api from "assets/media/gifs/internet/API.gif";
import Security from "assets/media/gifs/home/Security.gif";

import Voyance from "assets/media/images/internet/voyance.png";
import Payrouse from "assets/media/images/internet/payrouse.png";
import Tellerpay from "assets/media/images/internet/tellerpay.png";

import Ebot from "assets/media/images/customers/profiles/ebot.png";
import Bashir from "assets/media/images/customers/profiles/bashir.png";
import Oluwatobi from "assets/media/images/customers/profiles/oluwatobi.png";

import Invoices from "assets/media/video/retail/Invoices.mp4";
import Dashboard from "assets/media/video/retail/Dashboard.mp4";
import Integrations from "assets/media/video/home/Integrations.mp4";

export const QUALITIES = [
  {
    image: Fast,
    title: "Fast account opening",
    description: "Open a commercial-grade current account from anywhere fast.",
  },
  {
    image: Api,
    title: "API",
    description:
      "Use our composable API to build custom solutions to grow your business.",
  },
  {
    image: Security,
    title: "Security",
    description:
      "Rely on bank-grade security with all your monies fully insured by NDIC",
  },
];

export const FEATURES = [
  // {
  //   video: Cards,
  //   title: "Cards",
  //   description: "Make transactions with our physical or virtual debit card.",
  // },
  {
    video: Dashboard,
    title: "Dashboard",
    description:
      "Analyse your business' performance and its financial health in real time.",
  },
  {
    video: Invoices,
    title: "Invoices",
    description:
      "Phase out manual invoices. Create and send electronic invoices to get payments faster.",
  },
  {
    video: Integrations,
    title: "Integrations",
    description:
      "Leverage our APIs to connect with third-party tools to run your business efficiently.",
  },
  // {
  //   video: Dashboard,
  //   title: "Capital",
  //   description:
  //     "Enjoy easy access to financing to support your cashflow and business growth.",
  // },
  // {
  //   video: Dashboard,
  //   title: "Sub-Accounts",
  //   description:
  //     "Create accounts for specific purposes with its own unique account number.",
  // },
];

export const STORIES = [
  {
    logo: "Payrouse",
    story:
      "Brass has all the features a modern day business needs. Segmented payments, multisig approvals and a lot more. Their API makes sending payments to users automatically so reliably smooth.",
    name: "Bashir Aminu",
    title: "Co-founder, Payourse Inc",
    image: Payrouse,
    profile: Bashir,
  },
  {
    logo: "Voyance",
    story:
      "Brass has provided us with a strategic backup solutions beyond our expectations, responsiveness and support from their team is second to none.",
    name: "Ebot tabi",
    title: "Founder and CEO, Voyance",
    image: Voyance,
    profile: Ebot,
  },
  {
    logo: "",
    story:
      "Brass is accessible, with no rigorous but yet detailed account opening processes. When you say Brass, You hear a company that listens to their clients and we are happy to do business with them",
    name: "Oluwatobi Adewale-Temowo",
    title: "CEO, Tellerpay",
    image: Tellerpay,
    profile: Oluwatobi,
  },
];

export const frontmatter = {
  name: "internet-companies",
  supportItems: [
    {
      image: "../../assets/media/images/support.png",
      title: `<span>Our support team is <br /> your team</span>`,
      subtitle: "SUPPORT WHEN YOU NEED IT",
      description:
        "Our support team are a mix of bankers and growth experts ready to help you. With a Brass account, you get a team that is genuinely seeking to understand your business and provide solutions for its financial operations.  We are committed to going beyond issue resolution to becoming your partner in the growing and smooth running of your business. ",
    },
  ],
  resources: [
    {
      image: "../../assets/media/images/internet/Expense.png",
      title: "Expense Management",
      excerpt:
        "Track, pay, and audit employee-initiated expenses for adequate record keeping.",
    },
    {
      image: "../../assets/media/images/internet/USD.png",
      title: "USD balance",
      excerpt: "Hold USD and send money to international recipients.",
    },
    // {
    //   image: "../../assets/media/images/retail/Payroll.png",
    //   title: "Payroll services",
    //   excerpt:
    //     "Automate employee salaries, file taxes and remit pension from one place.",
    // },
  ],
  faqContent: [
    {
      question: "Which third-party tools can be used with the Brass API?",
      answer:
        "You can manage your finance without leaving your company slack channel. You also have access to financial management systems like quickbooks.",
    },
    // {
    //   question: "How much Capital is my account allowed?",
    //   answer:
    //     "We currently have a maximum capital limit of 10million naira. However, exceptions can be made based on the type of your business.",
    // },
    {
      question: "How do I set up invoices with my account?",
      answer:
        "With a Brass account, you do not need to worry about setting up your invoices. Simply log in, go to the invoice tab and start creating them.",
    },
    // {
    //   question: "Can I get a virtual or physical card for a sub account?",
    //   answer:
    //     "Our cards are not linked to any account, they hold their own balances and can be funded from any of your accounts.",
    // },
  ],
};
