import Fast from "assets/media/gifs/retail/Fast.gif";
import Support from "assets/media/gifs/home/Support.gif";
import Security from "assets/media/gifs/home/Security.gif";

import Payments from "assets/media/video/home/Payments.mp4";
import Invoices from "assets/media/video/retail/Invoices.mp4";
import Dashboard from "assets/media/video/retail/Dashboard.mp4";
import TeamBanking from "assets/media/video/home/TeamBanking.mp4";
import MobileApplication from "assets/media/video/retail/MobileApplication.mp4";
import MediaCashflowSupport from "assets/media/video/home/CashflowSupport.mp4";

export const QUALITIES = [
  {
    image: Fast,
    title: "Fast account opening",
    description: "Open a commercial-grade current account from anywhere fast.",
  },
  {
    image: Security,
    title: "Security",
    description:
      "Rely on bank-grade security with all your monies fully insured by NDIC",
  },
  {
    image: Support,
    title: "Support",
    description:
      "Get access to a trusted support team and resources whenever you want",
  },
];

export const FEATURES = [
  {
    video: Dashboard,
    title: "Dashboard",
    description:
      "Get a complete view of your business' performance and its financial health round the clock.",
  },
  {
    video: Invoices,
    title: "Invoices",
    description: "Create and send invoices to your customers on one platform.",
  },
  {
    video: TeamBanking,
    title: "Team banking",
    description:
      "Bank conveniently with your team, assign roles and permissions for seamless control of your retail account.",
  },
  // {
  //   video: Payments,
  //   title: "Payments and terminals",
  //   description:
  //     "Make online or in-store sales and receive your money that same day.",
  // },
  {
    video: MobileApplication,
    title: "Mobile application",
    description:
      "Monitor and stay on top of your business transactions from your mobile phone.",
  },
  // {
  //   video: MediaCashflowSupport,
  //   title: "Financing",
  //   description:
  //     "Enjoy easy access to financing to support your cashflow and business growth",
  // },
];

export const frontmatter = {
  name: "retail",
  hero: {
    mobileBackground: "../../assets/media/images/retail.png",
    background: "../../assets/media/images/retail/hero.png",
  },
  resources: [
    // {
    //   image: "../../assets/media/images/retail/Payroll.png",
    //   title: "Payroll by Brass",
    //   excerpt:
    //     "Automate employee salaries, file taxes and remit pension from one place, in minutes.",
    // },
    {
      image: "../../assets/media/images/retail/Inventory.png",
      title: "Inventory management",
      excerpt:
        "Keep track of your inventory and simplify every order associated with your retail account",
    },
    {
      image: "../../assets/media/images/retail/Online.png",
      title: "Online presence",
      excerpt:
        "Take your business online with a custom, shareable webpage that serves and promotes your business.",
    },
  ],
  faqContent: [
    {
      question: "How does team banking work for my account?",
      answer:
        "Team banking allows you add your team members to your account therefore making it easier to run shop with the right roles and permissions.",
    },
    {
      question: "How do I set up invoices with my account?",
      answer:
        "With a Brass account, you do not need to worry about setting up your invoices. Simply log in, go to the invoice tab and start creating them.",
    },
    // {
    //   question: "What do I need to apply for a POS Terminal machine?",
    //   answer:
    //     "To apply for a POS Terminal, all you need is an average daily volume of 100,000 reflected on your account activity.",
    // },
    // {
    //   question: "What do I need to pre-qualify for Capital?",
    //   answer:
    //     "To pre-qualify for capital, you would need to have been continually using your Brass account for a minimum period of 3-6 months, and you get the cashflow support you need. No long requirement forms necessary. ",
    // },
  ],
};
