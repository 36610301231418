import { SUPPORT_EMAIL, SUPPORT_NUMBER } from "data/config";

export const frontmatter = {
  name: "help",
  hero: {
    background: "../../assets/media/images/help-hero.png",
  },
  faqData: [
    {
      visible: true,
      title: "General",
      content: [
        {
          question: "What is Brass?",
          answer:
            "Brass is a service that provides top-end financial products for local businesses, providing smart payment tools for business growth.",
        },
        {
          question: "What does Brass come with?",
          answer: `
            <ul>
            
              <li>An NDIC-Insured NUBAN current account ready for business. </li>  <li>Payments to take care of payables
              and receivables.</li> <li>Corporate cards.</li> <li> Cash withdrawal. </li> <li>Cashflow analytics.</li> <li>Team banking
              with roles and privileges.</li>
              <li>Invoices</li>
            </ul>
          `,
        },
        {
          question: "Who can sign up on Brass?",
          answer: `We serve registered and unregistered businesses in Nigeria. We support sole traders, NGOs, partnerships and limited liabilities. Not a registered business owner? We can help you connect with our partners to register your business.`,
        },
        {
          question: "What do I need to open a Brass account?",
          answer:
            "To open a Brass account, you will need government-issued IDs i.e. International passport, National ID and BVNs. You will also need your company’s incorporation documents.",
        },
        {
          question:
            "Why do you ask for a government issued ID and company documents?",
          answer: ` <ul><li> It is required by the Nigerian law and regulation, which we operate within, for all business accounts to be duly KYC-ed.</li>
            <li> It helps us protect you better against fraudulence and money laundering cases that may arise from, but are not limited to, wrong or unapproved persons opening an account for your business without your permission.</li>
            <li> It protects us as a business from fraud risks so that we may serve legitimate businesses by approved people.</li></ul>`,
        },
      ],
    },
    {
      visible: true,
      title: "Account Management",
      content: [
        {
          question: "How do I generate my account statement?",
          answer: `Log in to your Brass account > Select accounts section on the side > Click on the "Get statement" > Fill in the time-frame and any other information that comes up.`,
        },
        {
          question: "How do I initiate Legal search payment?",
          answer:
            "Login to your account and select the option “pending task” to learn more about legal search and proceed to make payment. You can choose to make payment via your debit card or a direct deduction from your Brass account.",
        },
        {
          question: "What if I don't pay for Legal search?",
          answer:
            "Your business will be limited to a cumulative transaction limit of ₦50,000 until a legal search is conducted.",
        },
        {
          question: "What is a board resolution?",
          answer:
            "This is a document used to confirm the signatories to the company's account. Although, in the case of a sole proprietorship, a sole signature suffices.",
        },
        {
          question: "How do I submit my board resolution?",
          answer:
            "You can choose to upload your personal board resolution letter or use the sample template provided in the settings tab of your Brass account",
        },
        {
          question:
            "How can I cancel and resend request to my account reference?",
          answer: `Send the business name and email address of the account that you would like to cancel to us at <b>help@trybrass.com</b>. We will delete it from our end and notify you when to proceed with a request.`,
        },
      ],
    },
    {
      visible: true,
      title: "Team Banking",
      content: [
        {
          question: "Can I add my team members to my Brass account?",
          answer:
            "Yes, Brass supports team banking and that allows you add your team members to your account.",
        },
        {
          question: "Can I remove a team member?",
          answer:
            "As an owner, you have the ability to remove team members, but you cannot remove another owner.",
        },
        {
          question: "How do I remove a team member?",
          answer:
            "Log into your Brass account > Click on the teams page and remove the team member you would like to.",
        },
        {
          question: "What are the roles that a team member can attain?",
          answer: `We currently have three roles that have preset privileges.
          - Owner
          - Admin
          - Payment Admin`,
        },
        {
          question: "If I remove a team member, will they be notified?",
          answer: "The team member will not be notified.",
        },
        {
          question: "Can I manage the privileges of my team member?",
          answer:
            "Yes, you can. Log in to your Brass account > head over to the teams page> tap on the team member you wish to manage and change their role to a new one.",
        },
        {
          question:
            "Is there a limit and fees associated with adding team members to my account?",
          answer:
            "There is no limit to adding team members. There are also no fees associated with adding people to your account. However, we recommend that you keep your team size to a maximum of 10.",
        },
      ],
    },
    {
      visible: true,
      title: "Payments",
      content: [
        {
          question: "How do I fund my account?",
          answer:
            "Log in to your Brass account > Select new action > Click on add money > Select either USSD code or bank transfer to fund your account.",
        },
        {
          question: "What are the ways to fund my account?",
          answer: `
            <ul>
              <li>You can share your NUBAN account number with your customers to accept payment.</li>
              <li>You can send money using your USSD code.</li>
              <li>You can make a transfer from your other accounts.</li>
              <li>If you currently accept payment online, you can update your settlement account with your payment providers.</li>
              <li>You can generate a unique payment link and share with your customers.</li>
            </ul>
          `,
        },
        {
          question: "What is my daily transfer limit?",
          answer: `
          There is a limit for single transactions, which is ten million naira for registered businesses. The daily transfer limit for Brass accounts depends on the account type.
          <br/>
          <br/>Maximum daily outward:
          <br/>
            <ul>
              <li>LLC - N55M</li>
              <li>NGOs - N40M</li>
              <li>Partnership - N40M</li>
              <li>Sole proprietorship - N20M</li>
              <li>Unregistered businesses -  N5M</li>
            </ul>
          `,
        },
        {
          question: "Do you charge for transfers?",
          answer:
            "Local transfer fee for payment to other banks is typically N52.50 or less, depending on the bank. However, when you add a beneficiary as your contact, you can make transfers to them for free.",
        },
        // {
        //   question: "Can I make salary payments with my account?",
        //   answer:
        //     "Yes, you can make salary payments and manage your entire payroll operations using Payroll by Brass. Log in to your Brass account and go to the Payroll tab to get started.",
        // },
        {
          question: "Do you offer overdrafts?",
          answer: "We currently do not offer overdrafts to customers.",
        },
        {
          question: "Do you support international payments?",
          answer:
            "Not yet. We are working on that and would inform you when you can make international payments.",
        },
        {
          question: "Do you support cash deposit?",
          answer: `We do not currently support cash deposits.`,
        },
      ],
    },
    {
      visible: false,
      title: "Debit Card",
      content: [
        {
          question: "Can I withdraw cash at ATM?",
          answer:
            "Our issued cards are accepted where every Mastercard is accepted.",
        },
        {
          question:
            "What should I do with a suspicious transaction on my card?",
          answer:
            "If you see a suspicious transaction on your card, login to your dashboard to cancel or flag your card as stolen for a replacement. You can also reach out to your account manager so we can investigate.",
        },
        {
          question: "How do I replace a stolen card?",
          answer:
            "Log in to your Brass account > Go to your dashboard > Request for a new card associated with your account, and get it delivered to your office in 3-5 business days.",
        },
        {
          question: "Can I freeze my card?",
          answer:
            "Yes, you can. To see how, log into your Brass account > Select Cards > Click on the card type > Select freeze card > Put in your OTP > Freeze card.",
        },
        {
          question: "How do I create a new card?",
          answer:
            "Log into your Brass account > Select cards > Fill in card information > Select your new card pin.",
        },
        {
          question: "When will my debit card arrive?",
          answer:
            "Your debit card is delivered 3-5 working days after you have placed an order for one. ",
        },
      ],
    },
    {
      visible: true,
      title: "Security and Privacy",
      content: [
        {
          question: "Why do you ask for my BVN?",
          answer: `Here are a few reasons why we ask for your BVN
          <ul>
          <li> It is legally required to have a BVN before one can proceed to open an account in Nigeria.</li>
          <li> It is extra data that lets us validate that the authorised person is opening the account for the business. This protects you and your business. </li>
          <li> It serves us a business to protect ourselves against fraudulent attempts and people, so we only provide our services to legitimate people and businesses.</li></ul>`,
        },
        {
          question: "How do you ensure safekeeping of my data and money?",
          answer: ` <ul><li> We never store your plain text. All passwords are encrypted.
          </li><li> We access all pages on Brass over secure HTTPS and let the browser connect to us only via secure connection.
          </li><li> We advise our customers to turn on 2 Factor Authentication (2FA) to verify every login and payment transactions by using global standard authenticator apps instead of hackable channels such as the SMS.
          </li><li> We keep a log of your logins, sessions and devices which gives you complete visibility into your account so you can make decisions about them.
          </li><li> As an extra step, when we see a transaction from an unfamiliar device, location or IP address,  we send you a message to confirm it was you.</li></ul>`,
        },
        {
          question: "What does 2FA mean for Brass?",
          answer: `2FA is a process of verifying identity that allows for higher security on the web. This is because it combines 2 factors for verifying an identity:

          <ol >
          <li><b>What you know</b>:  The thing that should be known about the identity is the “password”
         </li> <li> <b>What you have</b>: This allows us to further verify identity by providing information from something on your person (it could be physical or electronic) — in this case, it will be the 2FA application on your phone/computer or access to your email inbox </li> </ol>`,
        },
        {
          question: "How do I connect Authy to my Brass account?",
          answer:
            "Install Authy from your App or Playstore. Log into your Brass account > Go to your settings > Select Personal > Click on security and follow the steps.",
        },
        {
          question: "Where do you keep my money?",
          answer:
            "Your monies are kept with our partners at Wema Bank & Titan Trust Bank and can be accessed at any time.",
        },
        {
          question: "How do I keep my account safe?",
          answer: `<ol><li> Do not use the same passwords across multiple websites on the internet; you can use a password manager for security or the built-in password management tools in your browser.
          </li><li>  As much as possible, do not log in on unknown devices — laptops & phones.
          </li><li>  Always turn on your 2FA for an extra level of verification. Recommended method: Google Authenticator or Authy.
          </li><li>  Always go through your Security logs periodically to review your security details and take recommended actions to keep your account safe.
          </li><li>  When onboarding team members to your account, ensure you check the privileges so you don’t grant control or access that you do not intend to.</li> </ol>`,
        },
        {
          question: "Are my deposits safe?",
          answer: "Your deposits are safe.",
        },
      ],
    },
    {
      visible: true,
      title: "Pricing",
      content: [
        {
          question: "How much do I pay for Legal Search?",
          answer: `Currently, we charge N12,000 for Legal search and this is completed in 3-5 business days.`,
        },
      ],
    },
    {
      visible: true,
      title: "Monthly service charge",
      content: [
        {
          question: "What does the monthly service charge mean?",
          answer:
            "The monthly service charges are fees on all outward transactions on your brass account. This is the same as the Commission on Turnover (COT charge). However, this is applied across different amount tiers, with a cap of N50,000 and it is debited at the end of every month.",
        },
        {
          question: "How much is the Monthly service charge?",
          answer: `The monthly service charge is applied in bands/tiers.<br/> Here is a summary of how the monthly fee is applied across different amount tiers:<br/>
            <div style='overflow-x: auto;'>
              <table>
                <thead>
                  <tr>
                    <th>Range(₦)</th>
                    <th>Charge per Mille(₦)</th>
                    <th>Charge per Naira(₦)</th>
                    <th>Cap(₦)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.00 - 1,500,000.00</td>
                    <td>1.00</td>
                    <td>0.00100</td>
                    <td>1,500</td>
                  </tr>
                  <tr>
                    <td>1,500,001.00 - 20,000,000.00</td>
                    <td>0.65</td>
                    <td>0.00065</td>
                    <td>13,000</td>
                  </tr>
                  <tr>
                    <td>20,000,001.00 - 50,000,000.00</td>
                    <td>0.50</td>
                    <td>0.00050</td>
                    <td>25,000</td>
                  </tr>
                  <tr>
                    <td>50,000,001.00 - 100,000,000.00</td>
                    <td>0.40</td>
                    <td>0.00040</td>
                    <td>40,000</td>
                  </tr>
                  <tr>
                    <td>100,000,001.00 - Infinity</td>
                    <td>0.30</td>
                    <td>0.00030</td>
                    <td>50,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          `,
        },
        {
          question: "How is the Monthly Service Charge applied?",
          answer:
            "The Monthly Service Charge charge is debited from any of your accounts sufficiently funded to bear the charge. This could either be the main account or sub-accounts (If you created one)",
        },
      ],
    },
    {
      visible: true,
      title: "Payment link",
      content: [
        {
          question: "What is a payment link?",
          answer:
            "Payment links are a convenient and secure way to collect payments online and deposit them directly into your Brass account or physical point-of-sale device.",
        },
        {
          question: "How do I use the payment link to make a payment?",
          answer: `Log in to your account <br/> On the left side of your screen, click the “Accounts” tab, <br/> Click on “Share payment link”. <br/> Copy the payment link and share it with your customers to receive payments directly into your account.`,
        },
        {
          question:
            "What payment methods are accepted through the payment link?",
          answer: "Credit/debit cards, Bank transfers, USSD, Visa QR.",
        },
        {
          question:
            "Are there any additional fees or charges for using the payment link?",
          answer:
            "Payment link incurs a processor fee of approximately 1.15% for each transaction.",
        },
        {
          question: "How long is a payment action valid?",
          answer:
            "Payment links are valid for 30mins once the payment is initiated.",
        },
        {
          question: "Can I use the payment link on mobile devices?",
          answer:
            "The payment link is mobile-friendly and accessible on various devices.",
        },
        {
          question:
            "Is there any customer support available for payment-related inquiries?",
          answer: `Our support email address: <a href='mailto:${SUPPORT_EMAIL}' target='_blank'>${SUPPORT_EMAIL} </a> <br/> Our customer support line: <a href='tel:${SUPPORT_NUMBER}' target='_blank'>${SUPPORT_NUMBER}</a>`,
        },
      ],
    },
    {
      visible: false,
      title: "Capital",
      content: [
        {
          question: "How do I become eligible for a loan?",
          answer:
            "An invite is sent to Brass customers who have been using their account consistently for at least six months. No collateral or credit check required.",
        },
        {
          question: "How long can I take a loan for?",
          answer:
            "Our capital loans are accessible for a maximum of 4 weeks for new users and 12 weeks for returning customers with a breakdown of the repayment amount and date.",
        },
        {
          question: "How do I repay a loan?",
          answer:
            "Repayments are automatically debited from your Brass main account at the due date. Alternatively, Repayments can also be made manually by clicking Repay now in your repayment schedule at no extra cost.",
        },
        {
          question: "How soon do I start repaying the loan?",
          answer:
            "As soon as you want. We have created a system where you can decide the frequency in which you would like to repay your loans. You can either pay daily, weekly or monthly. Whichever plan works for you, you get to pay the same interest rate.",
        },
      ],
    },

    {
      visible: true,
      title: "Sub-Accounts",
      content: [
        {
          question:
            "Is there an authorisation process for opening a sub account?",
          answer:
            "No authorisation process required for creating a sub-account",
        },
        {
          question: "Can the monthly budget set be changed?",
          answer:
            "You can change the monthly budget set on your sub-account. Log into your Brass account > Go to Accounts > Account settings > Set your new budget and save changes.",
        },
      ],
    },
    {
      visible: true,
      title: "Invoices",
      content: [
        {
          question: "Will I be charged for creating Invoices?",
          answer:
            "We have a 1% charge on all invoices sent. However this percentage will be capped at N10,000. This means the highest amount you would ever be charged is N10,000",
        },
        {
          question: "How do I set up invoices on my account?",
          answer:
            "Log in to your Brass account, go to the invoice tab and start creating.",
        },
        {
          question:
            "When I send my invoice instalments, will I be paid all at once?",
          answer:
            "When you select pay with Brass, all your instalments would be treated as separate invoices to help you with your bookkeeping processes.",
        },
        {
          question: "Can I delete an instalment?",
          answer: "Instalments can be deleted by clicking on remove.",
        },
        {
          question:
            "Can I edit my invoice once I receive my date for instalment payments?",
          answer:
            "Once you have decided on a date for your instalment(s), you can edit and send it later.",
        },
        {
          question:
            "How can I receive payment after I have sent out an invoice?",
          answer:
            "Your customers can either pay directly into your Brass account, make a payment if they use Brass or pay using our online secure link.",
        },
        {
          question: "Can I accept payments in all currencies?",
          answer:
            "You can currently only accept payments in Naira through bank transfer or our secure payment link",
        },
      ],
    },
    {
      visible: false,
      title: "Terminals",
      content: [
        {
          question: "Do I need to have a Brass account to own a POS Terminal?",
          answer:
            "Yes you do. This is where all payments made are reflected in.",
        },
        {
          question: "How fast will the POS terminal be delivered?",
          answer:
            "If you are business owner in Lagos, your POS terminal will be delivered 48 working hours after confirmation of request.",
        },
        {
          question: "How many POS Terminals can I request for?",
          answer: "You can request for as many terminals as you want.",
        },
        {
          question: "What are the types of POS Terminals available?",
          answer:
            "We currently have only android terminals available for sale.",
        },
        {
          question: "What cards are accepted?",
          answer: "Naira Debit Cards (Visa, Mastercard and Verve)",
        },
        {
          question: "How long does it take before I get settled in my account?",
          answer: "Payments are settled instantly.",
        },
        {
          question: "How does the Buy Now Pay Later option work?",
          answer: "The Buy Now Pay Later option is currently unavailable.",
        },
      ],
    },
    {
      visible: false,
      title: "Payroll",
      content: [
        {
          question: "How much do I pay for Brass Payroll?",
          answer: `Payroll is free for businesses that don’t pay taxes and statutory deductions if company payroll is less than 500,000 naira.<br/><br/> 7,500 if company payroll is within 500,000 and 999,999. 15,000 naira if company payroll is over 1million.<br/><br/>While for businesses that pay taxes and statutory deductions, Payroll is priced at 25,000 base fee with an additional 500 per employee onboarded.`,
        },
        {
          question: "Can I pause my payroll at any time?",
          answer: "Yes you can.",
        },
        {
          question: "What are the list of things I can do with Brass Payroll?",
          answer:
            "With Brass Payroll, you can easily onboard and manage your employees, collect statutory deductions, add and remove items like bonuses and repayments to employees, segment your payroll by teams or locations, download reports, manage your company pension and taxes, and so much more.",
        },
        {
          question: "How many times can I run payroll in a month?",
          answer: "You currently can only run payroll once a month. ",
        },
        {
          question: "How will my employees get their payslip?",
          answer:
            "Employees can download their payslips any time through their personal payroll portal which will be set up once their payroll invite has been approved by the company.",
        },
        {
          question:
            "What information do I need to get started on Brass Payroll?",
          answer:
            "Nothing, anyone who has a Brass account can set up their company payroll in minutes.",
        },
        {
          question:
            "Can I calculate pension contributions on the payroll account?",
          answer:
            "Yes you can. You can also add the pension providers your employees use. ",
        },
        {
          question: "Can I use Brass payroll for part-time employees?",
          answer:
            "Brass payroll works for both full-time and part-time employees.",
        },
        {
          question:
            "Is there a limit to the number of employees that can be onboarded on Brass payroll?",
          answer:
            "There's no limit to the number of employees you can add to your payroll.",
        },
      ],
    },
  ],
};
