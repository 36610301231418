import React from "react";
import { Link } from "gatsby";
import { SUPPORT_EMAIL, SUPPORT_NUMBER } from "data/config";

export default {
  title: "Cards Terms & Conditions",
  lastUpdated: "October 30, 2024",
  descMarkup: (
    <>
      <b>Terms and conditions for Debit Card</b>
      <br />
      <br />
      Your application for and use of a debit card is subject to the following
      terms and conditions.
      <br />
      <br />
      By accepting this terms and conditions, you agree that you have received,
      read, understood and agree to be bound by each of the rules set out below.
      Remember you must always keep to these rules, as they are a binding
      agreement between you and us. Please contact us if you need further
      explanation on anything related to the use of your card.
      <br />
      <br />
      You may contact us at{" "}
      <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
        {SUPPORT_EMAIL}
      </a>{" "}
      or speak to a customer support team member on{" "}
      <a href={`tel:${SUPPORT_NUMBER}`} target="_blank">
        {SUPPORT_NUMBER}
      </a>
      <br />
      <br />
      <b>Interpretation</b>
      <br />
      <br />
      <b>“Account”</b> means the account opened in the name of the account
      holder.
      <br />
      <br />
      <b>“Account holder”</b> means the person who has applied for and will be
      granted a card linked to his account and who will use the card for its
      intended purpose.
      <br />
      <br />
      <b> “Acquirer”</b> means the bank or financial institution that processes
      transactions for products or services provided by a merchant.
      <br />
      <br />
      <b>“ATM”</b> means an automated teller machine.
      <br />
      <br />
      <b>“Card”</b> means the debit card including any additional, renewal or
      replacement card(s) that we issue to you once you open an account with us
      and after we have approved your application.
      <br />
      <br />
      <b>“Verve”</b> and <b>“Mastercard”</b> means Verve International and
      Mastercard Incorporated and its authorized agents.
      <br />
      <br />
      <b>“Safetoken”</b> means the additional four-digit code given to you for
      added security against unauthorized use of your card when you shop with
      participating online merchants.
      <br />
      <br />
      <b>“MasterCard Secure code”</b> means the additional four-digit code given
      to you for added security against unauthorized use of your card when you
      shop with participating online merchants.
      <br />
      <br />
      <b>“Merchant”</b> means a supplier of goods and services.
      <br />
      <br />
      <b>“PIN”</b> means your personal identification number. You will be issued
      an initial PIN together with the card which you will be required to change
      when you first use your card.
      <br />
      <br />
      <b>“Transaction”</b> includes, but is not limited to, any payments or
      withdrawals or refunds arising on your account.
      <br />
      <br />
      <b>“We”</b>,<b>“Us”</b> or <b>“Brass”</b> means{" "}
      <b>COPPER BRASS NIGERIA LIMITED</b>
      <br />
      <br />
      <b>“You”</b> or <b>“Your”</b> means the account holder.
      <br />
      <br />
      <b>“SMS”</b> means short message service which is a text messaging service
      component of a phone, web or mobile communication system.
    </>
  ),
  sections: [
    {
      label: "1. Applying for a card",
      markup: (
        <ol>
          <li>Only a natural person may apply for a card</li>
          <li>
            Once the application form is completed, a card is issued to the
            account holder.
          </li>
        </ol>
      ),
    },
    {
      label: "2. Your card",
      markup: (
        <ol>
          <li>
            Once you receive your card, you will be required to select your four
            digits PIN at the point of activating your card.
          </li>
          <li>
            You should never disclose your PIN to a third party or write down or
            record your PIN in a manner that it would be easily understood by a
            third party.
          </li>
        </ol>
      ),
    },
    {
      label: "3. Using your card",
      markup: (
        <ol>
          <li>
            The Card must be used for only lawful transactions within Nigeria or
            the country where the goods or services are being purchased. You may
            only use the Card issued in your name.
          </li>
          <li>
            Your Card has an expiry date and is valid until the last day of the
            month shown on the Card, unless your account is closed or the Card
            is revoked as per Clause 6 below.
          </li>
          <li>
            The PIN you selected for your card enables you to use electronic
            banking facilities to carry out transactions such as withdrawals
            from ATMs, purchases on Point-of-Sale terminals, and purchases
            through secure online channels.
          </li>
          <li>
            When you use your Card for transactions on other channels which are
            not owned by Brass, the rules of use of that channel apply in
            addition to these rules.
          </li>
          <li>
            All transactions will be effected on your monthly account
            statements.
          </li>
          <li>
            By using your Card, you accept and agree to be bound by these rules.
          </li>
        </ol>
      ),
    },
    {
      label: "4. Unauthorized use of your card and PIN",
      markup: (
        <ol>
          <li>
            You are responsible for the safekeeping and proper use of your Card.
            You are strongly advised to memorize your PIN.
          </li>
          <li>
            Deactivate your Card immediately if you realise you have lost your
            Card, if your Card has been stolen, or if your PIN has been
            compromised. You may also notify Us, and We will stop the use of
            your Card and will “hotlist” it as soon as reasonably possible after
            being advised to do so.
          </li>
          <li>
            You will be responsible for all transactions made with your Card
            before you deactivate or notify us to stop the use of your Card as
            stated in Clause 2 above.
          </li>
          <li>
            You may dispute that any purchase or withdrawal charged to your card
            account was not authorized by you. We will investigate it after we
            receive a documented request from you confirming that you did not
            authorize the transaction, and other evidence as may be requested.
          </li>
          <li>
            We may call you from time to time to confirm certain transactions.
            To avoid the inconvenience of a security block being put on your
            Card, please ensure that the contact details we have for you are
            always up to date.
          </li>
        </ol>
      ),
    },
    {
      label: "5. Authority to charge your Account",
      markup: (
        <ol>
          <li>
            Any transaction you make using your Card, will be charged to your
            Account. The transaction is your authority to us to pay merchants
            and to charge the amount concerned to your Account. You cannot
            withdraw this authority.
          </li>
          <li>
            For all transactions, you must advise us in writing within 120 (one
            hundred and twenty) days of the date of any unauthorized
            transactions you may receive in your account. You will have no claim
            if you do not advise us within that period.
          </li>
          <li>
            For unauthorized transactions in your Account which you have
            reported to us within 120 (One hundred and twenty) days of the date
            of the unauthorized transaction, we would process your dispute in
            line with Verve’s and Mastercard’s chargeback procedures. For
            transactions within Nigeria, 3 (three) working days from the time we
            log the dispute against the Acquirer and for transactions outside
            Nigeria (within 45 (forty five) working days from the date we log
            the dispute against the Acquirer. Successful chargebacks will be
            credited to your Account. However, you cannot use a claim you have
            against a third party to make a claim against us, unless you have a
            legal right to do so.
          </li>
          <li>
            Where a safetoken and mastercard secure code is made available for
            online transactions, you are required to register for such service.
          </li>
          <li>
            We may verify and confirm any record of a deposit into your Account.
            Our records will be taken as correct unless the contrary is proved.
          </li>
        </ol>
      ),
    },
    {
      label:
        "6. Closing of your account and cancelling or revoking of your card",
      markup: (
        <ol>
          <li>
            You must advise us in writing if you want to close your account or
            cancel your card. You must destroy and return the card(s) to us with
            your letter instructing us to close your account or cancel your
            card.
            <br />
            To destroy a card so it cannot be used again:
            <br />
            <ul>
              <li>Cut through the Chip and account number</li>
              <li>
                Scratch out the numbers on the signature panel of the card.
              </li>
            </ul>
          </li>
          <li>
            A card that is not destroyed correctly may still be used. Should
            this happen, you will be responsible for any transactions effected
            with the card.
          </li>
          <li>
            We may choose, at any time, to revoke your card or to close your
            account to protect our interests.
          </li>
        </ol>
      ),
    },
    {
      label: "7. Consents and conduct of the account",
      markup: (
        <ol>
          <li>Disclosure within Brass</li>
          <li>
            You consent to us sharing information relating to your card
            application, card, or account, with any of our affiliates or
            associates within Brass for all purposes, including marketing.
          </li>
          <li>
            You agree that we may request or authorize any of our affiliates or
            associates within or outside Brass to perform any or all our
            obligations under these rules and that any of our affiliates or
            associates within or outside Brass may exercise our rights under
            these rules. We may disclose information relating to your
            application, card or account to our affiliates or associates within
            or outside Brass for purposes of the above including card issuing,
            administration, dispute handling and debt collection.
          </li>
        </ol>
      ),
    },
    {
      label: "8. Malfunction of electronic facilities",
      markup: (
        <>
          We are not responsible for any loss arising from any failure,
          malfunction or delay in any point-of-sale terminal, or ATM, or our
          supporting or shared networks, where applicable, resulting from
          circumstances beyond our reasonable control. However, this does not
          affect your rights to a chargeback for any unauthorized transaction
          that may occur in your account as a result of such malfunction or
          failure.
        </>
      ),
    },
    {
      label: "9. Addresses for notices",
      markup: (
        <ol>
          <li>
            The street address you supply on your application form will be
            regarded as your billing address and your chosen address where
            notices may be given and documents in legal proceedings may be
            served. You must immediately notify us in writing, sent via email if
            your name, chosen street address or email addresses changes.
          </li>
          <li>
            You should send any legal notice to us at our chosen address: 50
            Raymond Njoku, Ikoyi, Lagos, Nigeria.
          </li>
          <li>
            You acknowledge and agree that our agreement will be regarded as
            having been entered into in Nigeria and any breach of this agreement
            will be considered as having taken place in Nigeria.
          </li>
          <li>
            We are entitled, but not obliged, to send you any notice in terms of
            these rules to the email address you specified on your application
            form. Such email communication will be regarded as having been
            received by you unless the contrary is proved.
          </li>
          <li>
            Any correspondence that we send to you by post will be considered to
            have arrived within 7 (seven) days of posting and any correspondence
            that we send to you by fax or email will be considered to have
            arrived on the day that it was sent and any correspondence we send
            to you by courier will be considered to have arrived when delivery
            was acknowledged at your chosen address.
          </li>
          <li>
            We are obliged by law to regularly update your personal particulars,
            such as your residential address and contact information. We may
            contact you from time to time in this regard.
          </li>
        </ol>
      ),
    },
    {
      label: "10. General",
      markup: (
        <ol>
          <li>
            We do not warrant that the services that we provide outside these
            rules will always be available; we reserve the right to withdraw or
            vary these terms by giving you written notice. However, the failure
            or omission in giving such notice will not invalidate such
            amendment.
          </li>
          <li>
            You will be charged for any loss we may incur from your breach of
            these rules.
          </li>
          <li>
            Brass will not be liable, if we are unable to provide any part of
            our services for reasons beyond our control such as power failure,
            government regulations, strikes etc known as “force majeure”. If we
            cannot produce or send your statements for reasons beyond our
            control, or you are unable to receive the statements, you will still
            be bound by these rules.
          </li>
          <li>
            We will charge you for the replacement of your cards requested by
            you in cases of lost or stolen cards and for re-issued cards in
            event of renewals.
          </li>
          <li>
            You may not vary these rules. You cannot transfer or assign any
            rights you have under these rules to a third party.
          </li>
          <li>
            These rules will be governed by and interpreted in accordance with
            the laws of Nigeria. The card(s) will be administered by Brass.
          </li>
          <li>
            Any favour or concession we may give you will not affect any of our
            rights against you. Our non-enforcement of any clause and/or a
            breach of these rules or a delay in enforcing any clause and/ or
            breach will not prevent us from enforcing same against you.
          </li>
          <li>
            You must tell us immediately if you are under a bankruptcy order,
            become insolvent or have any other form of legal disability.
          </li>
          <li>
            We can close your account, restrict activity, or suspend access to
            your account if we in any way know or suspect that your account is
            being used fraudulently, negligently or for illegal activities or if
            we must do so to comply with the law or any regulation that is
            binding on us, without notice to you.
          </li>
          <li>
            If we close or suspend access to your account for any reason, we
            will not be liable to you for any direct, indirect, consequential,
            or special damages arising from any act or omission by us or any
            third party for whom we are responsible, whether arising in
            contract, or statute.
          </li>
          <li>
            For your security, we may record phone conversations between you and
            us. We may do this to ensure your instructions are adhered to and to
            provide you with a high quality of service.
          </li>
        </ol>
      ),
    },
  ],
};
