import React from "react";
import { guidesPageUrl } from "data/pageUrl";

import HelpBusinessGuide from "assets/media/images/launchpad/help-guide.png";
import HelpCommunity from "assets/media/images/launchpad/help-community.png";
import MediaStart from "assets/media/images/launchpad/start.png";
import MediaRun from "assets/media/images/launchpad/run.png";
import MediaGrow from "assets/media/images/launchpad/grow.png";
import MediaExpand from "assets/media/images/launchpad/expand.png";
import { BBC_WEBSITE_URL } from "data/config";

export const PLATFORM_DATA = [
  {
    title: "Start",
    media: MediaStart,
    desc: `Become a certified entrepreneur by registering your business and obtaining a TIN with our simple forms. Your business documents will be ready within 7-10 working days.`,
  },
  {
    title: "Run",
    media: MediaRun,
    // desc: `Run your business efficiently with up to 3 months of free transfers on your Brass Business Account and enjoy 3 months free access to Brass Invoice, Brass Payroll, and Brass Terminals`,
    desc: `Run your business efficiently with up to 3 months of free transfers on your Brass Business Account and enjoy 3 months free access to Brass Invoice, and Brass Terminals`,
  },
  {
    title: "Grow",
    media: MediaGrow,
    desc: `Access comprehensive resources and guides, and gain access to free finance and legal templates. Save up to ₦100,000 worth of discounts from our partners`,
  },
  {
    title: "Expand",
    media: MediaExpand,
    desc: `Expand your business with collateral-free loans to support your cash flow. Enjoy flexible repayment plans after running your Brass Business account for 6 months.`,
  },
];

export const STORIES = [
  {
    image: HelpBusinessGuide,
    text: "Top business guides and resources you need to get started, run and grow your business.",
    url: guidesPageUrl,
  },
  {
    image: HelpCommunity,
    text: "Join our community of entrepreneurs sharing their stories, tips and impacts.",
    url: BBC_WEBSITE_URL,
  },
];

export const frontmatter = {
  name: "launchpad",
  hero: {
    background: "../../assets/media/images/help-hero.png",
  },
  faqContent: [
    {
      question: "What is a Limited Liability Company?",
      answer:
        "LLCs are suitable for organisations that plans to raise capital in exchange for shares, and require licenses to scale and run their business",
    },
    {
      question:
        "Will Launchpad open a business bank account for my new company?",
      answer:
        "Yes. Launchpad works to ensure that all customers open a business bank account.",
    },
    {
      question: "What do I need to get started?",
      answer: "Your Name and Your Email address",
    },
    {
      question: "What is the price for Sole Proprietorship?",
      answer: "N30,000",
    },
  ],
  faqPageData: [
    {
      visible: true,
      title: "Incorporation",
      content: [
        {
          question: "What is a Limited Liability Company?",
          answer:
            "LLCs are suitable for organisations that plans to raise capital in exchange for shares, and require licenses to scale and run their business",
        },
        {
          question: "What is a Sole proprietorship?",
          answer:
            "A Sole Proprietorship is suitable for SMEs that do not plan to issue shares or require regulatory licenses to run.",
        },
        {
          question: "Who are Shareholders?",
          answer:
            "Shareholders are the owners of the company. They provide financial backing in return for potential dividends over the lifetime of the company.",
        },
        {
          question: "Who are Directors?",
          answer:
            "Directors are officers of a company who are appointed to operate the business for the benefit of the shareholders.",
        },
        {
          question: "What is Share capital?",
          answer:
            "Share capital is the total amount for the total shares of your company and the minimum share capital by regulation is 1 Million Naira.",
        },
        {
          question: "What is TIN?",
          answer:
            "A Taxpayer Identification Number (TIN) is an identification number used by the Internal Revenue Service (IRS) in the administration of tax laws.",
        },
        {
          question: "How long does it take to get my business registered?",
          answer:
            "It takes an average of 7-10 working days and getting TIN for a sole proprietorship takes an additional 2 working days",
        },
      ],
    },
    {
      visible: true,
      title: "Buisness Bank Account",
      content: [
        {
          question:
            "Will Launchpad open a business bank account for my new company?",
          answer:
            "Yes. Launchpad works to ensure that all customers open a business bank account.",
        },
        {
          question: "How long will it take to get a Bank account?",
          answer:
            "It takes 1-2 working days after business registration to get a Business Bank account from Brass",
        },
        {
          question: "Can I get access to loan?",
          answer:
            "Yes. After running your bank account for a minimum of 6 months, you’ll qualify for a working capital loan",
        },
        // {
        //   question: "Can I get a POS terminal?",
        //   answer:
        //     "Yes. You can request for a POS terminal for your business operation.",
        // },
      ],
    },
    {
      visible: true,
      title: "Getting Started",
      content: [
        {
          question: "What do I need to get started?",
          answer: "Your Name and Your Email address",
        },
        {
          question:
            "What documents will I get after registering a Sole Proprietorship business?",
          answer: `<ul>
          <li>Certificate of registration</li>
          <li>A stamped application form</li>
          <li>TIN</li>
          </ul>`,
        },
        {
          question:
            "What documents will I get after registering a Limited Liability Company?",
          answer: `<ul>
          <li>Certificate of incorporation</li>
          <li>Stamped Status Report (which has now replaced the previous CAC 1.1 that used to be issued)</li>
          <li>Stamped Memorandum and Article of Association</li>
          <li>TIN</li>
          </ul>`,
        },
      ],
    },
    {
      visible: true,
      title: "Pricing",
      content: [
        {
          question: "What is the price for Sole Proprietorship?",
          answer: "N30,000",
        },
        {
          question: "What is the price for Limited Liability Company?",
          answer: "N75,000",
        },
        {
          question: "Do I need to pay extra for TIN?",
          answer: "No. The price is inclusive of TIN registration",
        },
      ],
    },
    {
      visible: true,
      title: "Support",
      content: [
        {
          question: "What if I have more question?",
          answer: `You can reach us via mail on <a href='mailto:help@trybrass.com' target='_blank'>help@trybrass.com</a> or chat with us via live chat `,
        },
      ],
    },
    {
      visible: true,
      title: "Documents Required ",
      content: [
        {
          question: "Business Name ",
          answer: `<ul>
          <li>Passport Photograph of Proprietor</li>
          <li>Means of Identification of Proprietor</li>
          <li>BVN</li>
          <li>E-Signature</li>
          </ul>`,
        },
        {
          question: "Limited Liability",
          answer: `<ul>
          <li>Passport Photograph of Directors and Shareholders</li>
          <li>Means of Identification of Directors and Shareholders</li>
          <li>BVN</li>
          <li>E-Signature</li>
          </ul>`,
        },
      ],
    },
  ],
};
