import React from "react";

import Odu from "assets/media/images/home/odu.png";
import Speed from "assets/media/gifs/home/Speed.gif";
import Support from "assets/media/gifs/home/Support.gif";
import Hustler from "assets/media/images/home/hustler.png";
import NiFries from "assets/media/images/home/nifries.png";
import Security from "assets/media/gifs/home/Security.gif";
import SupportImage from "assets/media/images/support.png";
import Simi from "assets/media/images/home/simi-hustler.png";
import MediaInvoices from "assets/media/video/home/Invoices.mp4";
import MediaPayments from "assets/media/video/home/Payments.mp4";
import Olakunbi from "assets/media/images/home/olakunbi-odu.png";
import Feranmi from "assets/media/images/home/feranmi-nifries.png";
import MediaTeamBanking from "assets/media/video/home/TeamBanking.mp4";
import MediaIntegrations from "assets/media/video/home/Integrations.mp4";
import MediaCashflowSupport from "assets/media/video/home/CashflowSupport.mp4";

export const QUALITIES = [
  {
    image: Speed,
    title: "Speed",
    description: "Open a commercial-grade current account from anywhere.",
  },
  {
    image: Security,
    title: "Security",
    description:
      "Rely on bank-grade security with all your monies fully insured by NDIC",
  },
  {
    image: Support,
    title: "Support",
    description:
      "Get access to a trusted support team and resources whenever you want",
  },
];

export const FEATURES = [
  {
    title: "Payments",
    description: " Create instant, scheduled and recurring payments easily",
    media: MediaPayments,
  },
  {
    title: "Invoices",
    description: " Send, receive, and reconcile invoices all on one platform",
    media: MediaInvoices,
  },
  {
    title: "Cashflow Support",
    description:
      "Move your business forward with insight and financial resources",
    media: MediaCashflowSupport,
  },
  {
    title: " Cross-platform availability",
    description: "Bank everywhere. Available on iOS, Android and Slack",
    media: MediaIntegrations,
  },
  {
    title: "Team banking",
    description: "Work with your team to run your entire financial operations",
    media: MediaTeamBanking,
  },
];

export const SUPPORT_ITEMS = [
  {
    image: SupportImage,
    title: (
      <>
        Our support team is <br className="text-break" /> your team
      </>
    ),
    subtitle: "SUPPORT WHEN YOU NEED IT",
    description: (
      <>
        Our support team are a mix of bankers and growth experts ready to help
        you. With a Brass account, you get a team that is genuinely seeking to
        understand your business and provide solutions for its financial
        operations. <br /> We are committed to going beyond issue resolution to
        becoming your partner in the growing and smooth running of your
        business.
      </>
    ),
  },
];

export const TESTIMONIALS = [
  {
    name: "Simi Adejumo, Hustler Capital",
    content:
      "We have to move really fast with our portfolio companies and Brass has helped us validate a lot of ideas and turned them to businesses with the ease of creating sub-accounts.",
    image: Simi,
    logo: Hustler,
  },
  {
    name: "Olakunbi Adedipe, Odu Fashion",
    content:
      "The ease of opening an account completely won me over. The app is very easy to navigate and it’s obvious they have the best interests of SMEs at heart. I’m proud to call them my bank.",
    image: Olakunbi,
    logo: Odu,
  },
  {
    name: "Feranmi Ajetunmobi, Ni-Fries",
    content:
      "Brass, with the sub-accounts feature, makes it easy to segment our cash and in turn optimise it for targeted goals.",
    image: Feranmi,
    logo: NiFries,
  },
];

export const frontmatter = {
  name: "home",
  supportItems: [
    {
      image: "../../assets/media/images/support.png",
      title: `<span>Our support team is <br class="text-break" /> your team</span>`,
      subtitle: "SUPPORT WHEN YOU NEED IT",
      description: `Our support team are a mix of bankers and growth experts ready to help you. With a Brass account, you get a team that is genuinely seeking to understand your business and provide solutions for its financial operations. <br/> <br/>  We are committed to going beyond issue resolution to becoming your partner in the growing and smooth running of your business.`,
    },
  ],
  resources: [
    {
      image: "../../assets/media/images/naira.png",
      title: "Why you need a separate business current account",
      link: "https://medium.com/brass-resources/why-you-need-a-separate-business-current-account-f5e81c29cfd9",
      excerpt: "The reason for them and how to open a Brass account",
    },
    {
      image: "../../assets/media/images/idea.png",
      title: "Working on the right idea",
      link: "https://trybrass.com/guides/starting-a-business/working-on-the-right-idea",
      excerpt: "How to figure out the right business idea for you.",
    },
    {
      image: "../../assets/media/images/business-credit.jpg",
      title: "What you need to know about business credit",
      link: "https://medium.com/brass-resources/what-you-need-to-know-about-business-credit-7e57f7f0547d",
      excerpt: "How business credit can work for you.",
    },
  ],
  faqContent: [
    {
      question: "Who can sign up on Brass?",
      answer:
        "We currently only serve registered businesses in Nigeria. We support sole traders, NGOs, partnerships and limited liability companies. However, If your business is not yet registered, Brass can help you get your desired business registered on time with our partners.",
    },
    {
      question: "How much do I pay to sign up at Brass?",
      answer: "You do not pay for signing up for an account at Brass",
    },
    {
      question: "What do I need to open an account?",
      answer:
        "You will need government-issued IDs (Including BVN) and your company's incorporation documents.",
    },
    {
      question: "How do you ensure safekeeping of my data and money?",
      answer:
        "We have introduced Two factor Authentication(2FA) for an extra level of security. This authentication is done using digital OTP apps like Google authenticator and Authy instead of hackable channels such as the SMS.",
    },
  ],
};
