import React from "react";
import { BRASS_BUSINESS_SUPPORT_URL, BRASS_PRESS_URL } from "data/config";

import Win from "assets/media/gifs/about/Win.gif";
import Press from "assets/media/images/about/press.png";
import Believe from "assets/media/gifs/about/Believe.gif";
import Careers from "assets/media/images/about/careers.png";
import Business from "assets/media/images/about/business.png";
import Efficiency from "assets/media/gifs/about/Efficiency.gif";
import Parternship from "assets/media/gifs/about/Partnership.gif";
import FirstClass from "assets/media/images/about/first-class.png";
import SupportOne from "assets/media/images/about/about-us-support-1.png";
import SupportTwo from "assets/media/images/about/about-us-support-2.png";
import SupportFour from "assets/media/images/about/about-us-support-4.png";
import SupportThree from "assets/media/images/about/about-us-support-3.png";
import { careersPageUrl, helpPageUrl } from "data/pageUrl";

export const CONTACT = [
  {
    image: FirstClass,
    title: "First-class support when you need it",
    description:
      "Get access to a trusted support team and resources whenever you want.",
    linkText: "Contact our support",
    link: helpPageUrl,
  },
  {
    image: Careers,
    title: "Career opportunities",
    description:
      "Join the Brass team, do exciting work that can help make enterprising more successful.",
    link: careersPageUrl,
    linkText: "Learn more about opportunities",
  },
  {
    image: Business,
    title: "Business and Partnership",
    description:
      "Contact our business team if you wish to talk or seek ways you can work with us on our mission.",
    link: BRASS_BUSINESS_SUPPORT_URL,
    linkText: "Contact our business team",
  },
  {
    image: Press,
    title: "Press and media",
    description:
      "Find latest news, press releases and updates on Brass, company information and executive bios and other press resources.",
    link: BRASS_PRESS_URL,
    linkText: "View press resources",
  },
];

export const PERKS = [
  {
    image: Believe,
    title: "We believe in you",
    description:
      "We know that you have what it takes to do great work and we are ready to provide the necessary support you need to succeed.",
  },
  {
    image: Parternship,
    title: "A partnership that pays",
    description:
      "We continuously seek to work with you, understand the products you need and know the partners we can bring onboard to help your business get ahead.",
  },

  {
    image: Win,
    title: "We win when you win",
    description:
      "The growth and success of our business is a direct result of our customers', which is why we work so hard to help them win.",
  },
  {
    image: Efficiency,
    title: "Efficiency and clarity",
    description:
      "We provide value by removing inefficiencies experienced with existing providers thus helping to simplify your life and give clarity into your business.",
  },
];

export const SUPPORT_ITEMS = [
  {
    image: SupportOne,
    title: (
      <>
        A full-stack, <br /> commercial-grade current account for your business
      </>
    ),
    description:
      "Get a current account that comes with the financial products, education and partnership you need to run and grow your business.",
  },
  {
    image: SupportTwo,
    title: (
      <>
        Smart tools to help <br />
        make business more efficient
      </>
    ),
    description:
      "Running a business can be demanding-managing your finances shouldn't be. Get access to multiple cards for your team with spending limits, automated payments, and an easy to use dashboard to track it all.",
  },
  {
    image: SupportThree,
    title: (
      <>
        A team that cares about <br /> your business
      </>
    ),
    description:
      "You are equipped with a dedicated team and resources ready to listen to you and take your business to the next level.",
  },
  {
    image: SupportFour,
    title: "Elimination of needless fees",
    description:
      "Our goal is to help your business grow while saving both time and money. We believe that running a business can be more efficient, and we have designed solutions to make that happen.",
  },
];

export const frontmatter = {
  name: "about",
  supportItems: [
    {
      image: "../../assets/media/images/about/about-us-support-1.png",
      title: `<span>
          A full-stack, <br /> commercial-grade current account for your business
        </span>`,
      description:
        "Get a current account that comes with the financial products, education and partnership you need to run and grow your business.",
    },
    {
      image: "../../assets/media/images/about/about-us-support-2.png",
      title: `<span>
          Fast financing when <br /> you need it
        </span>`,
      description:
        "Never run out of money when you want to build or grow your business. Get quick access to the financial resources you need. ",
    },
    {
      image: "../../assets/media/images/about/about-us-support-3.png",
      title: `<span>
          A team that cares about <br /> your business
        </span>`,
      description:
        "You are equipped with a dedicated team and resources ready to listen to you and take your business to the next level.",
    },
    {
      image: "../../assets/media/images/about/about-us-support-4.png",
      title: "Elimination of needless fees",
      description:
        "Our aim is to make sure that your business saves money while you are growing. We believe banking can be cheaper and we have provided alternatives to this.",
    },
  ],
};
