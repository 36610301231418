import { Aboki, Send, Voyance } from "assets/media/svgs";

import Fast from "assets/media/gifs/retail/Fast.gif";
import Idorenyin from "assets/media/images/capital/Idorenyin.png";
import Larry from "assets/media/images/capital/Larry.png";
import React from "react";
import Security from "assets/media/gifs/home/Security.gif";
import SupportImage from "assets/media/images/capitals.png";
import Turbo from "assets/media/gifs/capital/Turbo.gif";
import VoyanceGroup from "assets/media/images/capital/Voyance.png";

export const QUALITIES = [
  {
    image: Fast,
    title: "Fast account opening",
    description: "Open a commercial-grade current account from anywhere fast.",
  },
  {
    image: Turbo,
    title: "Turbo-charge your business",
    description: "Get the financial boost you need to grow your business.",
  },
  {
    image: Security,
    title: "Security",
    description:
      "Rely on bank-grade security with all your monies fully insured by NDIC",
  },
];

export const SUPPORT_ITEMS = [
  {
    image: SupportImage,
    title: (
      <>
        Big or small business,
        <br /> we are here to support you
      </>
    ),
    subtitle: "BUILT FOR GROWTH",
    description:
      "More than capital, our growth people work with you to understand your business growth needs. We look at your cashflow performance and history and advise accordingly.",
  },
];

export const TESTIMONIALS = [
  // {
  //   story:
  //     "Brass Capital was very helpful in increasing our working capital exactly when we needed it. The process was fast, smooth, and headache-free. Trust me, you don't want to go through the same process with a traditional bank.",
  //   name: "Larry Oti",
  //   title: "Co-Founder and CEO, send",
  //   image: <Send />,
  //   profile: Larry,
  // },
  {
    story:
      "Brass has provided us with a strategic backup solutions beyond our expectations, responsiveness and support from their team is second to none.",
    name: "Ebot Tabi",
    title: "Founder and CEO, Voyance",
    image: <Voyance />,
    profile: VoyanceGroup,
  },
  {
    story:
      "We have been using Brass for 9 months now. We were in the market for a convenient float service and Brass more than delivered. Setting up an account with them was super quick and the onboarding process was smooth.",
    name: "Idorenyin Obong",
    title: "Co-founder, Aboki Africa",
    image: <Aboki />,
    profile: Idorenyin,
  },
  // {
  //   story:
  //     "Brass Capital helped us with illiquidity when we had a huge shortage. For us it was emergency funds at the right time. The low barrier to entry and ease of application helped us solve our liquidity issues quickly and efficiently.",
  //   name: "Tola Adesanmi",
  //   title: "CEO, Spleet Africa",
  //   image: <Spleet />,
  //   profile: Tola,
  // },
];
export const frontmatter = {
  name: "capital",
  supportItems: [
    {
      image: "../../assets/media/images/capitals.png",
      title: `<span>Big or small business,
      <br /> we are here to support you</span>`,
      subtitle: "BUILT FOR GROWTH",
      description:
        "More than capital, our growth people work with you to understand your business growth needs. We look at your cashflow performance and history and advise accordingly.",
    },
  ],
  resources: [
    {
      image: "../../assets/media/images/naira.png",
      title: "Why you need a separate business current account",
      link: "https://medium.com/brass-resources/why-you-need-a-separate-business-current-account-f5e81c29cfd9",
      excerpt: "The reason for them and how to open a Brass account",
    },
    {
      image: "../../assets/media/images/idea.png",
      title: "Working on the right idea",
      link: "https://trybrass.com/guides/starting-a-business/working-on-the-right-idea",
      excerpt: "How to figure out the right business idea for you.",
    },
    {
      image: "../../assets/media/images/naira.png",
      title: "Why you need a separate business account",
      excerpt: "Every business needs a separate account.",
    },
  ],
  faqContent: [
    {
      question: "How soon do I start repaying the loan?",
      answer:
        "As soon as you want. We have created a system where you can decide the frequency in which you would like to repay your loans. You can either pay daily, weekly or monthly. Whichever plan works for you, you get to pay the same interest rate.",
    },
    {
      question: "How do I repay a loan?",
      answer:
        "Repayments are automatically debited from your Brass main account at the due date. Alternatively, Repayments can also be made manually by clicking Repay now in your repayment schedule at no extra cost.",
    },
    {
      question: "How long can I take a loan for?",
      answer:
        "Our capital loans are accessible for a maximum of 4 weeks for new users and 12 weeks for returning customers with a breakdown of the repayment amount and date.",
    },
    {
      question: "How do I become eligible for a loan?",
      answer:
        "An invite is sent to Brass customers who have been using their account consistently for at least six months. No collateral or credit check required.",
    },
  ],
};
